import { Box } from '@mui/material';
import React from 'react';

const Paragraph = ({ text, overrideStyles, ...props }) => {
  return (
    <Box
      component="p"
      mb={0}
      mt={0}
      fontSize="14px"
      fontWeight="400"
      lineHeight="1.5"
      sx={{
        ...overrideStyles,
      }}
      {...props}
    >
      {text}
    </Box>
  );
};

export default Paragraph;
